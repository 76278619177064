import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError, interval } from 'rxjs';
import { map, catchError, retryWhen, flatMap } from 'rxjs/operators';
import { of, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpHeaders } from '@angular/common/http';
import { Http, ResponseContentType } from '@angular/http';
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import 'rxjs/add/operator/switchMap';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesServiceService {
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  internal_params:any={};

  public headers: HttpHeaders = new HttpHeaders();
  token: string;
  task_id: string;
  domain = 'cl';
  constructor(
  	 public http: HttpClient,
    public jwtHelper: JwtHelperService,
    private toastr:ToastrService,
  	) {
    if (JSON.parse(localStorage.getItem('currentUser'))){
      this.token = JSON.parse(localStorage.getItem('currentUser')).token;
      this.headers = this.headers.append('Authorization', 'Bearer '+ this.token);
    }
    if (!environment.url_front.includes('localhost')) {
      const domainParts = environment.url_front.split('.');
      this.domain = domainParts[domainParts.length - 1].slice(0, -1);
    } else {
      this.domain = 'localhost';
    }
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  findProductDescription(code:string): any{
  	return this.http.get(environment.url+'product_sale/', {params: {search:code}, headers: this.headers})
          .pipe(
            map((response: any) => {
                return response.results;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  createSale(register: any): Observable<boolean> {
    console.log(register);
  	return this.http.post(environment.url+'sale/',register, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  };

  downloadSaleBill(sale_id: any): Observable<boolean> {
    return this.http.get(environment.url + 'sale-bill/', {params: {sale_id: sale_id}, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  downloadSaleCreditNote(sale_id: any): Observable<boolean> {
    return this.http.get(environment.url + 'sale-credit-note/', {params: {sale_id: sale_id}, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  userData(): any{
    return this.http.get(environment.url+'users/',  {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response.results[0];
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  listSales(params:any): any {
    if(params.channel == "all"){
      params.channel = "";
    }
    this.internal_params.channel = params.channel
    if (params.date_update_date !== null){

    params.date_update_date = params.date_update_date.getFullYear() + "-" + (params.date_update_date.getMonth() + 1) +"-" + params.date_update_date.getDate();
    }else {

      delete params.date_update_date;
    }

      return this.http.get(environment.url+'sale/', {params:params,headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })

        )

  }

  getBill(id:any){
    return this.http.get(environment.url_open_factura +'/v2/dte/document/' + id +'/pdf', {headers:{"apikey": environment.api_token_open_factura}})
    .pipe(
      map((response: any) => {
        return response
      })
 )


  }
  getSaleData(id:number): any {
    return this.http.get(environment.url + 'sale/' + id +'/', {headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })

        )
  }

  updateSale(register: any): Observable<boolean> {
    // console.log(register);
    return this.http.put(environment.url+'sale/'+register.id_venta + "/", register, {headers: this.headers})
          .pipe(
            map((response: any) => {
              // console.log(response);
                return true;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  };

  reprintSale(register: any): Observable<boolean> {
    return this.http.put(environment.url + 'sale-reprint/' + register.id_venta + "/", register, {headers: this.headers})
      .pipe(
        map((response: any) => {
          return true;
        }),
      );
  };

  deleteSale(register_id: number): Observable<boolean> {
    return this.http.delete(environment.url+'sale/'+register_id + "/", {headers: this.headers})
          .pipe(
            map((response: any) => {
              // console.log(response);
                return true;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  sendRetailData(data:any): any {
    return this.http.post(environment.url+'massive/retail/data', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
              // console.log(response);
                return true;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    // // console.log(data)
  }
  listRetailSales(params:any): any {
    // // console.log(params)
    if (params.channel__name === 'all'){
      this.internal_params.channel = '';
    }else{
      this.internal_params.channel=params.channel__name;
    }
    this.internal_params.is_twenty_four_sale= null
    if (params.channel__name === 'Paris 24 Horas'){
      this.internal_params.channel= 4
      this.internal_params.is_twenty_four_sale= true
    }
    this.internal_params.status = params.status;
    this.internal_params.shipping_type = params.shipping_type;
    this.internal_params.shipping_status = params.shipping_status;
    this.internal_params.shipping_type_sub_status = params.shipping_type_sub_status;
    this.internal_params.sale_of_product__product_code = params.sale_of_product__product_code;
    this.internal_params.page = params.page;
    this.internal_params.number_document = params.number_document;
    this.internal_params.sale_of_product = params.sale_of_product;
    this.internal_params.reaconditionated_sale = params.reaconditionated_sale;
    this.internal_params.sale_reaconditionated_product__reaconditionated_label = params.sale_reaconditionated_product__reaconditionated_label;
    // this.internal_params.date_sale = '2018-11-30'

    return this.http.get(environment.url+'retail-sales/', {params:this.internal_params,headers:this.headers})
           .pipe(
             map((response: any) => {
               // // console.log(response);
               return response
             })

        )
  }

  listBlockCount(){
    return this.http.get(environment.url+'list_block_count', {headers: this.headers})
      .pipe(
        map((response: any) => {
          console.log(response);
            return response;
        }),
      )
  }

  updateSubStatusShipping(): any {
   return this.http.get(environment.url+'update/shipping-sub-status', {headers: this.headers})
            .pipe(
              map((response: any) => {
                // console.log(response);
                  return true;
              }),
              // catchError((error: HttpErrorResponse | any) => {
              // // console.log("nopo");
              // })
            )
  }

  updateSaleRetail(data: any): Observable<boolean> {
    // console.log(data);
    return this.http.put(environment.url+'retail-sales/'+data.id + "/", data, {headers: this.headers})
          .pipe(
            map((response: any) => {
              // console.log(response);
                return true;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  };


  generateAllSaleRetail(data, task_in, includeShippingRequestParam, includeShippingInDocument): any {
    let params;
    if (includeShippingRequestParam) {
      params = (task_in != null ? {task_id: task_in} : {generate_bills: 'true', include_shipping: includeShippingInDocument.toString()});
    } else {
      params = (task_in != null ? {task_id: task_in} : {generate_bills: 'true'});
    }
    return this.http.post(environment.url + 'retail-sales/', data, {headers: this.headers, params: params})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          );
    // // console.log(data)
  }

  validateStockStore(data): any {
    let params = {generate_bills:"true",first_validate:'true'}
    return this.http.post(environment.url+'retail-sales/', data, {headers: this.headers, params:params})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    // // console.log(data)
  }

  validateCodeSupervisor(code){
    let params = {code_supervisor:"true"}
    return this.http.post(environment.url+'retail-sales/', {code:code}, {headers: this.headers, params:params})
    .pipe(
      map((response: any) => {
          return response;
      }),
      // catchError((error: HttpErrorResponse | any) => {
      // // console.log("nopo");
      // })
    )
  }

  exportExcel(all:boolean, file_name:string): any{
    const params = {}
    if (!all){
      let date=new Date();
      let format_date = date.getFullYear() + "-" + (date.getMonth() + 1) +"-" + date.getDate();
      params['date_filter'] = format_date;
    }
    // // console.log(params);
    return this.http.get(environment.url+'export/sales/xlsx', {params:params,headers: this.headers, responseType: 'arraybuffer'})
    .subscribe(response => this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", file_name))
  }

  exportExampleExcel(file_name:string): any{
    // // console.log(params);
    return this.http.get(environment.url+'export/sales/retail/example', {headers: this.headers, responseType: 'arraybuffer'})
    .subscribe(response => this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", file_name))
  }


  downLoadFile(data: any, type: string, file_name:string):any{
        const d = new Date(),
        dformat = [d.getFullYear(),
               d.getMonth() < 10 ? ("0" + String(d.getMonth() +1)):(d.getMonth() +1),
               d.getDate() < 10 ? ("0" + String(d.getDate())):(d.getDate())].join('')+
              [d.getHours() < 10 ? ("0" + String(d.getHours())):(d.getHours()),
               d.getMinutes() < 10 ? ("0" + String(d.getMinutes())):(d.getMinutes())].join('');
        let file_date = dformat;
        // // console.log(file_date);
        const blob = new Blob([data], { type: type});
        const fileName: string = file_name + " " + file_date + ".xlsx";
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

  search(terms: Observable<string>, params: any) {
    return terms.debounceTime(650)
      .distinctUntilChanged()
      .switchMap((term, params) => this.searchEntries(term, params));
  }
searchSales(terms: Observable<string>, params: any) {
  return terms.debounceTime(650)
      .distinctUntilChanged()
      .switchMap((term, params) => this.searchEntrieSales(term, params));
}
  searchEntries(term, params) {
    // // console.log(params)
    return this.http.get(environment.url + 'retail-sales/',{params:{search:term, status:this.internal_params.status, channel:this.internal_params.channel, sale_of_product__product_code:this.internal_params.sale_of_product__product_code, number_document:this.internal_params.number_document, sale_of_product:this.internal_params.sale_of_product, shipping_type_sub_status:this.internal_params.shipping_type_sub_status, reaconditionated_sale:this.internal_params.reaconditionated_sale, sale_reaconditionated_product__reaconditionated_label:this.internal_params.sale_reaconditionated_product__reaconditionated_label},headers:this.headers})
    .pipe(
        map((res) => {
          // // console.log(res);
          return res;
        })
      )
  }
  searchPack(pack){
    return this.http.get(environment.url + 'pack/',{params:{search:pack},headers:this.headers})
    .pipe(
        map((res) => {
          // // console.log(res);
          return res;
        })
      )
  }

  searchEntrieSales(term, params) {
    // // console.log(params)
    return this.http.get(environment.url + 'sale/',{params:{search:term, channel:this.internal_params.channel},headers:this.headers})
    .pipe(
        map((res) => {
          // // console.log(res);
          return res;
        })
      )
  }
  generateBillOpenFactura(data, response_type){
    return this.http.post(environment.url+'generate-bill/', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  updateSalesRipley(data:any) {
    return this.http.post(environment.url+'automatic/retail/sales/ripley', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
updateSalesLinio(data:any) {
    return this.http.post(environment.url+'automatic/retail/sales/linio', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  updateSalesMercadoshop(data:any) {
    return this.http.post(environment.url+'automatic/retail/sales/mercadoshop', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  generateShippingDocument(data:any) {
    return this.http.post(environment.url+'generate/shipping-document/', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  generateLabelPaidMlService(data:any) {
    return this.http.post(environment.url+'label-shipping-paid-ml/', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  searchProduct(terms: Observable<string>) {
    return terms.debounceTime(600)
      .distinctUntilChanged()
      .switchMap((term) => this.searchEntrieProducts(term));
  }
  searchEntrieProducts(term) {
    // // console.log(params)
    return this.http.get(environment.url+'product_sale2/',{params:{search:term},headers:this.headers})
    .pipe(
        map((res) => {
          // // console.log(res);
          res['term'] = term;
          return res;
        })
      )
  }
  sendGrouponData(data:any){
     return this.http.post(environment.url+'load/groupon', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  sendFalabellaData(data:any){
     return this.http.post(environment.url+'load/falabella', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  generateShippingGuide(data:any, params:any){
    return this.http.post(environment.url+'shipping/guide', data, {headers: this.headers, params:params})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  updateDeclaredTotal(row:any): Observable<boolean> {
    return this.http.patch(environment.url+'sale/'+row.id + "/", {total_declared:row.total_declared}, {headers: this.headers})
          .pipe(
            map((response: any) => {
              // console.log(response);
                return true;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    }

   sendParisData(data:any){
     return this.http.post(environment.url+'load/paris', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  generateBillParis(data:any, date_emition:string, params:any, file:any){
    let new_data:any;
    if (file != null){
      // new_data.file_upload = file;
      const formData: FormData = new FormData();
      formData.append('file_upload', file, file.name);
      formData.append('sales', data);
      formData.append('date_emititon', date_emition);
      new_data = formData;
    }else{
      let data_send:any = {"sales": data, "date_emititon": date_emition}
      new_data = data_send;

    }
    return this.http.post(environment.url+'generate/bill', new_data, {headers: this.headers, params:params})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  exportEmmited(data:any){
    let data_new = {"sales": data}
    return this.http.post(environment.url+'export/emmited', data_new, {headers: this.headers, responseType: 'arraybuffer'})
    .subscribe(response => this.downLoadEmmited(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
  }

   downLoadEmmited(data: any, type: string):any{

        const blob = new Blob([data], { type: type});
        const fileName: string = "Lista de Emitidas.xlsx";
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

 updateSalesMercadolibre(data:any){
   return this.http.post(environment.url+'automatic/retail/sales/mercadolibre', data, {headers: this.headers})
      .pipe(
        map((response: any) => {
            return response;
        }),
        // catchError((error: HttpErrorResponse | any) => {
        // // console.log("nopo");
        // })
      )
 }
 creditNote(data:any){
    return this.http.post(environment.url+'cancel-bill/', data, {headers: this.headers})
      .pipe(
        map((response: any) => {
            return response;
        }),
        // catchError((error: HttpErrorResponse | any) => {
        // // console.log("nopo");
        // })
      )
 }

  changeBillDocument(data: any) {
    return this.http.post(environment.url + 'change-bill/', data, {headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  creditNoteForShipping(data: any) {
    return this.http.post(environment.url + 'credit-note-shipping/', data, {headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

 uploadManifiesto(files: File, channel_selected):any {
    const formData: FormData = new FormData();
    formData.append('file_upload', files, files.name);

    return this.http.post(environment.url+'retail-sales/', formData, {params:{manifiesto_upload:"1", channel:channel_selected, generate_bills:"true"},headers: this.headers})
          .pipe(
            map((response: any) => {

                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    // return this.http.post(environment.url + 'merge-labels/', formData, {headers:this.headers, responseType: 'arraybuffer'})
    // .subscribe(response => this.downLoadLabels(response, "application/pdf",))

  }

  validateStockStoreMassive(files: File, channel_selected):any {
    const formData: FormData = new FormData();
    formData.append('file_upload', files, files.name);

    return this.http.post(environment.url+'retail-sales/', formData, {params:{manifiesto_upload:"1", channel:channel_selected, generate_bills:"true",first_validate:'true'},headers: this.headers})
          .pipe(
            map((response: any) => {

                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    // return this.http.post(environment.url + 'merge-labels/', formData, {headers:this.headers, responseType: 'arraybuffer'})
    // .subscribe(response => this.downLoadLabels(response, "application/pdf",))

  }


  flagSale(id:number, rut, email){
    return this.http.patch(environment.url+'sale/'+id + "/", {rut:rut, email:email}, {headers: this.headers, params:{flag: "true"}})
          .pipe(
            map((response: any) => {
              // console.log(response);
                return true;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }
  reaconditionatedCodeUpdate(data){
    return this.http.patch(environment.url+'reaconditionated-products/99/' , data, {headers: this.headers, params:{flag: "true"}})
              .pipe(
                map((response: any) => {
                  // console.log(response);
                    return true;
                }),
                // catchError((error: HttpErrorResponse | any) => {
                // // console.log("nopo");
                // })
              )
  }
  sendFBFData(data:any){
     return this.http.post(environment.url+'load/fbf', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  sendF22Data(data:any){
    return this.http.post( environment.url + 'load-f22/', data, {headers: this.headers})
         .pipe(
           map((response: any) => {
               return response;
           }),
         )
  }

  checkFinalStatus(){
    return this.http.get(environment.url+'final-check/',{headers:this.headers})
    .pipe(
        map((res) => {
          return res;
        })
      )
  }
  requestStock(data){
    return this.http.put(environment.url+'product_sale_channels/99/', data, {headers:this.headers, params:{presential_request:"true"}})
           .pipe(
             map((response: any) => {
               return response
             }),

        )
  }

  sendSodimacData(data){
    return this.http.post(environment.url+'load/sodimac', data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  getReportPendingChannel(filter_type:string){
    return this.http.get(environment.url + 'sales/report/pending/channel', {params:{filter_type:filter_type}, headers:this.headers})
         .pipe(
           map((response: any) => {
             return response
           })
      )
  }
  getContributionChannel(filter_type:string){
     return this.http.get(environment.url + 'contribution/report', {params:{filter_type:filter_type}, headers:this.headers})
         .pipe(
           map((response: any) => {
             return response
           })
      )
  }

  productsCodes(): any{
    return this.http.get(environment.url+'product_code_data/',  {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  searchClient(params):any{
    return this.http.get(environment.url+'search-client',  {headers: this.headers, params:params})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  createBudget(budget_data){
    return this.http.post(environment.url+'budget/',budget_data, {headers: this.headers})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
  }

  downLoadExample(data:any, type: string, file_name:string):any{
    const blob = new Blob([data], { type: type});
    const fileName: string = "Archivo de ejemplo " + file_name + ".xlsx";
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
}

  dowloadExcelBudgetReport(){
    return this.http.get(environment.url+'report-budget-xlsx/', {headers: this.headers, responseType: 'arraybuffer', params:{example_file:"true"}})
    .subscribe(response => this.downLoadExample(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "Historico de Presupuestos"))
  }

  exportExampleFile(file_selected, file_name){
    return this.http.get(environment.url+'export/file_examples', {headers: this.headers, params:{file_selected:file_selected},responseType: 'arraybuffer'})
    .subscribe(response => this.downLoadExampleFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", file_name))
  }

  downLoadExampleFile(data:any, type: string, file_name):any{
        const blob = new Blob([data], { type: type});
        const fileName: string = file_name;
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    requestConfirmedBootic(params){
      return this.http.get(`${environment.url}bootic_confirmed`,  {headers: this.headers, params:params})
          .pipe(
            map((response: any) => {
                return response;
            }),
            // catchError((error: HttpErrorResponse | any) => {
            // // console.log("nopo");
            // })
          )
    }
    changePriceSales(change_data){
      return this.http.post(environment.url+'change-price-sales/',change_data, {headers: this.headers})
            .pipe(
              map((response: any) => {
                  return response;
              }),
              // catchError((error: HttpErrorResponse | any) => {
              // // console.log("nopo");
              // })
            )
    }
    massCancelation(change_data){
      return this.http.post(environment.url+'mass-cancelation/',change_data, {headers: this.headers})
            .pipe(
              map((response: any) => {
                  return response;
              }),
              // catchError((error: HttpErrorResponse | any) => {
              // // console.log("nopo");
              // })
            )
    }

    serviceUpdateMassBill(change_data){
      return this.http.post(environment.url+'update-mass-bill/',change_data, {headers: this.headers})
            .pipe(
              map((response: any) => {
                  return response;
              }),
              // catchError((error: HttpErrorResponse | any) => {
              // // console.log("nopo");
              // })
            )
    }

    updateOpexPices(change_data){
      return this.http.post(environment.url+'update-opex/',change_data, {headers: this.headers})
            .pipe(
              map((response: any) => {
                  return response;
              }),
              // catchError((error: HttpErrorResponse | any) => {
              // // console.log("nopo");
              // })
            )
    }

    progressCancelation(url_download, task_id_){
      return this.http.post(environment.url + url_download, task_id_, {headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })
        )
    }

    progressMassBill(url_download, task_id_){
      return this.http.post(environment.url + url_download, task_id_, {headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })
        )
    }

    progressUpdateOpex(url_download, task_id_){
      return this.http.post(environment.url + url_download, task_id_, {headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })
        )
    }

    uploadCostData(change_data){
      return this.http.post(environment.url+'upload-cost-data/',change_data, {headers: this.headers})
            .pipe(
              map((response: any) => {
                  return response;
              }),
              // catchError((error: HttpErrorResponse | any) => {
              // // console.log("nopo");
              // })
            )
    }

    subCategoryAsiamerica(){
      return this.http.get(environment.url + 'sub_category_product_xlsx', {headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })
        )
    }
    getReportDelayed(filter_type:string){
      return this.http.get(environment.url + 'delayed-report', {params:{filter_type:filter_type}, headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })
        )
    }
    startDownloadReportDelayed(url_download, params={}){
      return this.http.get(environment.url + url_download, {headers:this.headers, params:params})
           .pipe(
             map((response: any) => {
               return response
             })
        )
    }
    progressDownloadReportDelayed(url_download, task_id, type_){
      return this.http.get(environment.url + url_download, {params:{type_report:type_,task_id:task_id}, headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })
        )
    }
    uploadRegisterDelayed(){
      return this.http.get(environment.url + 'upload-register-delayed/', {headers:this.headers})
           .pipe(
             map((response: any) => {
               return response
             })
        )
    }
  generateShippingExcel(params:any){
    return this.http.post(environment.url+'generate-shipping-report/', {}, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response
        })
      )
  }

  automaticReportSaleService(params:any){
    return this.http.post(environment.url+'automatic-report-sale/', params, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response
        })
      )
  }

  consultStatusAutomaticReportSale(params:any){
    return this.http.post(environment.url+'automatic-report-sale/', params, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response
        })
      )
  }

  getFalabellaOrdersBoxes(params) {
    return this.http.get(environment.url + 'falabella-boxes/', {params: params, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  generateConfirSalesFalabella(payload){
    return this.http.post(environment.url+'confirm-sales-falabella-new/', payload, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response
        })
      )
  }

  consultStatusConfirSalesFalabella(params){
    return this.http.post(environment.url+'confirm-sales-falabella-new/', params, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response
        })
      )
  }


  generateReportPendingEmail(){
    return this.http.post(environment.url+'send-report-pending-sales/', {}, { headers: this.headers })
    .pipe(
      map((response: any) => {
        return response
      })
    )
  }

  generateReportPendingByChannel(){
    return this.http.post(environment.url+'send_report_pending_channel/', {}, { headers: this.headers })
    .pipe(
      map((response: any) => {
        return response
      })
    )
  }


  generateReportSalePendingEmail(){
    return this.http.post(environment.url+'pending-sale-report/', {}, { headers: this.headers })
    .pipe(
      map((response: any) => {
        return response
      })
    )
  }

  generateMassiveManifestChannel(data){
    return this.http.post(environment.url+'generate_manifest_massive_front/', {data:data}, { headers: this.headers })
    .pipe(
      map((response: any) => {
        return response
      })
    )
  }

  logisticResponsibleNotification(){
    return this.http.post(environment.url+'responsable_logistic_notification/', {}, { headers: this.headers })
    .pipe(
      map((response: any) => {
        return response
      })
    )
  }

  generateReportShipping(data){
    return this.http.post(environment.url+'generate-shipping-report-xlsx/', {data:data}, {headers: this.headers, responseType: 'arraybuffer'})
    .subscribe(response => {
      this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "Manifiesto de Envio")
      this.toastr.success("El se genero correctamente", "Bien");
    },error => {
      this.toastr.clear();
      this.toastr.error("Hubo un error al generar archivo", "Error");
    })
  }

  // Debe crearse en un nuevo modulo de warehouses
  listWarehouses(params: any) {
    return this.http.get(environment.url + 'warehouse/', {params: params, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response.results;
        })
      );
  }

  listBeetrackDispatchStatus(sale_id) {
    return this.http.get(environment.url + 'beetrack-dispatches', {params: {'sale_id': sale_id}, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response.results;
        })
      );
  }

  listDocumentTypeHistory(sale_id) {
    return this.http.get(environment.url + 'sale-document-type-history/', {params: {'sale_id': sale_id}, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  generateShippingGuides(payload) {
    return this.http.post(environment.url + 'generate-fbf-shipping-guides/', payload, {headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  listShippingGuides(params) {
    return this.http.get(environment.url + 'shipping-guides/', {params: params, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  downloadShippingGuide(id: any): Observable<boolean> {
    return this.http.get(environment.url + 'shipping-guide-pdf/', {params: {shipping_guide_id: id}, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  startDownload(url_download, params = {}) {
    return this.http.get(environment.url + url_download, { headers: this.headers, params: params })
      .pipe(
        map((response: any) => {
          return response
        })
      )
  }

  publicationsFileDownload(url_download, params, file_name) {
    let d = new Date,
      dformat = [d.getFullYear(),
      d.getMonth() < 10 ? ("0" + String(d.getMonth() + 1)) : (d.getMonth() + 1),
      d.getDate() < 10 ? ("0" + String(d.getDate())) : (d.getDate())].join('') +
        [d.getHours() < 10 ? ("0" + String(d.getHours())) : (d.getHours()),
        d.getMinutes() < 10 ? ("0" + String(d.getMinutes())) : (d.getMinutes())].join('');
    return this.http.get(environment.url + url_download, { params: params, headers: this.headers })
      .subscribe(
        response =>
          window.open(response["url"], '_blank')
      )
  }

  progressDownload(url_download, task_id, add_params) {
    return this.http.get(environment.url_prices_app + url_download, { params: { task_id: task_id, chammel_id:add_params.channel_id }, headers: this.headers })
      .pipe(
        map((response: any) => {
          return response
        })
      )
  }

  getPricesFileName(){
    return this.http.get(environment.url_prices_app + 'api/prices_file_name/')
      .pipe(
        map((response: any) => {
          return response
        })
      )
  }

  exportSalesReport(params): any {
    return this.http.get(environment.url + 'sales-report/', {params: params, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  downloadExcel(data: any, type: string, file_name: string, ext: string): any {
    const blob = new Blob([data], {type: type});
    const fileName: string = file_name + ext;
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }


  downloadExcelRedash() {
    this.http.post(environment.url + 'download-redash-report/',{}, {headers: this.headers, responseType: 'arraybuffer'})
      .subscribe(
        response => {
          this.downloadExcel(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'Contribuciones Negativas', '.xlsx');
        },
        error => {
          console.log(error);
          this.toastr.error('Error al generar los datos', 'Error');
        }
      );
  }

  downloadHistoryCostData() {
    return this.http.post(environment.url + 'download_historic_cost_data/', {}, {headers: this.headers, responseType: 'arraybuffer'}).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  download_excel_publications(): any {
    return this.http.get(environment.url + 'download_excel_publications/', {  headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  sync_data_warehouse(params): any {
    return this.http.get(environment.url + 'update_data_warehouse/', { params: params, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  generateSpecialComissions(payload : any) {
    return this.http.post(environment.url + 'generate-special-comissions/', payload, {headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  searchSpecialComission(terms: Observable<string>, params: any) {
    return terms.debounceTime(650)
      .distinctUntilChanged()
      .switchMap((term, params) => this.searchSpecCommis(term, params));
  }

  searchSpecCommis(term, params) {
    const options = {headers: this.headers, params: {}};
    if (term) {
      options.params = {search: term};
    }
    return this.http.get(environment.url + 'generate-special-comissions/', options)
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        })
      )
  }


  listSpecialComission(params: any): any {
    return this.http.get(environment.url + 'generate-special-comissions/', {params: params, headers: this.headers})
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  downloadCurrentComercialAgreements() {
    this.http.post(environment.url + 'download-comercial-agreements/',{}, {headers: this.headers, responseType: 'arraybuffer'})
      .subscribe(
        response => {
          this.downloadExcel(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'Contribuciones Negativas', '.xlsx');
        },
        error => {
          console.log(error);
          this.toastr.error('Error al generar los datos', 'Error');
        }
      );
  }


}
